import { message } from 'antd'
import ky from 'ky-universal'
import { host } from './config'

import directus from './directus'

const request = ky.extend({
  prefixUrl: host,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = directus.auth.token ?? ''

        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`)
        }
      }
    ],
    afterResponse: [
      (req, option, res) => {
        res
          .clone()
          .json()
          .then((data) => {
            // TODO: 非 directus 错误
            if (data?.msg === 'err') {
              message.error(data.data)
            }

            data?.errors?.forEach((error: any) => {
              message.error(error.message)
            })
          })
        return res
      }
    ]
  }
})

export default request
