import Router from '@/router'
import { BrowserRouter } from 'react-router-dom'
import './App.less'
import PointerError from './components/PointerError'

function App() {
  return (
    <PointerError>
      <BrowserRouter>
        <Router></Router>
      </BrowserRouter>
    </PointerError>
  )
}

export default App
