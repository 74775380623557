import request from './request'
import { GenCollention } from './directus_type'
/**
 * 新增成员 POST /users/insertUser
 */
export const ApiPostUser = async (params: API.UserInfo) => {
  return await request
    .post('users/dpos/createUser', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 编辑成员 POST /users/dpos/updateUser
 */
export const ApiPostUpdateUser = async (params: API.UserInfo) => {
  return await request
    .post('users/dpos/updateUser', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 修改DPO PATCH /items/users_roles/:id
 */
export const ApiPatchDPO = async (params: { id: string }) => {
  return await request.patch(`items/users_roles/${params.id}`, {}).json()
}

/**
 * 邮件发送 POST /email/emailSend
 */
export const ApiPostEmailSend = async (params: API.SendEmail) => {
  return await request
    .post('email/emailSend', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 忘记密码 POST /users/forgetPassword
 */
export const ApiPostForgetPassword = async (params: API.PasswordChange) => {
  return await request
    .post('users/forgetPassword', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 修改密码 POST /auth/changePassword
 */
export const ApiPostChangePassword = async (params: API.PasswordChange) => {
  return await request
    .post('auth/changePassword', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 注册用户 POST /users/dpos/register
 */
export const ApiPostRegister = async (params: API.Register) => {
  return await request
    .post('users/dpos/register', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 加入企业 POST /users/joinComany
 */
export const ApiPostJoinCompany = async (params: { email: string }) => {
  return await request
    .post('users/joinCompany', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 创建企业 POST /users/dpos/company
 */
export const ApiPostCreateCompany = async (params: API.CreateCompany) => {
  return await request
    .post('users/dpos/company', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 注销企业 POST /users/logoutCompany
 */
export const ApiPostLogoutCompany = async (params: API.LogoutCompany) => {
  return await request
    .post('users/logoutCompany', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 移除成员 POST /users/removeUser
 */
export const ApiPostRemoveUser = async (params: { email?: string }) => {
  return await request
    .post('users/removeUser', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 更换DPO PATCH /users/dpos/DPO/{id}
 */
export const ApiPatchDpo = async (id: string) => {
  return await request
    .patch('users/dpos/DPO', {
      json: {
        userId: id
      }
    })
    .json()
}

/**
 * 更换企业创建人 PATCH /users/dpos/company/{id}
 */
export const ApiPatchCompany = async (id: string) => {
  return await request
    .patch('users/dpos/company', {
      json: {
        userId: id
      }
    })
    .json()
}

/**
 * 系统角色列表接口 GET /users/dpos/roles
 */
export const ApiGetRoles = async () => {
  return await request('users/dpos/roles').json<
    {
      id: string
      name: string
    }[]
  >()
}

/**
 * 添加部门 POST /users/dpos/department
 */
export const ApiPostDepartment = async (
  params: GenCollention['department']
) => {
  return await request
    .post('users/dpos/department', {
      json: params
    })
    .json<{
      error?: unknown
    }>()
}

/**
 * 编辑部门 PATCH /users/dpos/department/{id}
 */
export const ApiPatchDepartment = async (params: {
  id: number
  data: GenCollention['department']
}) => {
  return await request
    .patch(`users/dpos/department/${params.id}`, {
      json: params.data
    })
    .json<{
      error?: unknown
    }>()
}

/**
 * 新增评估 - 查看人Select数据 POST /users/checkUser
 */
export const ApiPostCheckUser = async (params: { departmentId?: number }) => {
  return await request
    .post('users/checkUser', {
      json: params
    })
    .json<API.CheckUserResponses>()
}

/**
 * 评估列表 GET /assessment/page
 */
export const ApiGetAssessmentList = async (params: API.AssessmentParams) => {
  return await request('assessment/page', {
    searchParams: {
      limit: params.size || 10,
      ...params
    }
  }).json<API.AssessmentResponses>()
}

/**
 * 评估列表筛选Options GET /assessment/page/params
 */
export const ApiGetAssessmentOptions = async () => {
  return await request('assessment/page/params').json<API.AssessmentOptions>()
}

/**
 * 评估详情 上传文件类型组件 上传接口 POST assessment/file
 */
export const ApiPostUploadAssessment = async (params: FormData) => {
  return await request
    .post('assessment/file', {
      body: params
    })
    .json<{ file_url: string; file_name: string; file_type: string }[]>()
}

/**
 * 获取风险详情 POST /assessment/risk/comment
 */
export const ApiPostAssessmentRisk = async (
  params: API.AssessmentRiskParams
) => {
  return await request
    .post('assessment/risk/comment', {
      json: params
    })
    .json<API.RiskDetailsResponses>()
}
/*
 * 评估数据 GET /assessment/data/info
 */
export const ApiGetAssessment = async () => {
  return await request.get('assessment/data/info').json<API.TallData>()
}

/**
 * 数据处理活动 GET /assessment/activity/page
 */
export const ApiGetDataActivity = async (params: {
  limit: number
  name?: string
  page: number
}) => {
  return await request
    .get('assessment/activity/page', {
      searchParams: {
        ...params
      }
    })
    .json<API.TdataAssessmentActivity>()
}

/**
 * 数据处理活动详情 GET /assessment/activity/detail
 */
export const ApiGetDataActivityDetail = async (
  params: API.TdataAssessmentActivityDetailParams
) => {
  return await request
    .get('assessment/activity/detail', {
      searchParams: { ...params }
    })
    .json<API.TdataAssessmentActivityDetail>()
}

/**
 * 新增评估：审核人数据 GET /users/auditUser
 */
export const ApiGetAuditUser = async () => {
  return await request
    .get('users/auditUser')
    .json<{ first_name: string; id: string }[]>()
}

/**
 * 评估模板： 邮件发送 POST /assessment/email
 */
export const ApiPostAssessmentEmail = async (
  params: API.AssessmentEmailParams
) => {
  return await request
    .post('assessment/email', {
      json: params
    })
    .json()
}

/**
 * 网络安全事件列表 GET /networkIncident/page
 */
export const ApiGetNetworkSecurityList = async (
  params: API.NetworkSecurityListParams
) => {
  return await request('networkIncident/page', {
    searchParams: { ...params, limit: params.size || 10 }
  }).json<API.ResponseKyQuery<GenCollention['network_incident'][]>>()
}

/**
 * 新增网络安全事件 POST /networkIncident
 */
export const ApiPostAddNetworkIncident = async (
  params: API.NetworkSecurity
) => {
  return await request
    .post('networkIncident', {
      json: params
    })
    .json<{ data: GenCollention['network_incident'] }>()
}

/**
 * 获取网络安全事件风险详情 POST /assessment/risk/comment
 */

export const ApiCNSEventRiskDetails = async (params: {
  riskId?: number
  networkIncidentId?: number
}) => {
  return await request
    .post('assessment/risk/comment', {
      json: params
    })
    .json<API.RiskDetailsResponses>()
}

/**
 * 编辑网络安全事件 PATCH /networkIncident/{id}
 */

export const ApiPatchCNSEvent = async (params: {
  id: string
  values: API.NetworkSecurity
}) => {
  return await request
    .patch(`networkIncident/${params.id}`, {
      json: params.values
    })
    .json()
}

/*
 * 网络安全事件数据看板 GET /networkIncident/data/info
 */
export const ApiGetNetworkIncidentData = async () => {
  return await request
    .get('networkIncident/data/info')
    .json<API.NetworkSecurityDataDesk>()
}

/*
 * 删除网络安全事件子任务 DELETE /networkIncident/subtask/{id}
 */
export const ApiDeleteSubTask = async (id: number) => {
  return await request
    .delete(`networkIncident/subtask/${id}`)
    .json<API.DeleteSubtask>()
}

export const ApiPostRiskComment = async (params: API.AddRiskCommentParams) => {
  return await request
    .post('networkIncident/riskComment', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 删除网络安全事件风险 DELETE /networkIncident/risk/{id}
 */

export const ApiDeleteRisk = async (id: number) => {
  return await request
    .delete(`networkIncident/risk/${id}`)
    .json<API.ResponseKy>()
}

/**
 * 风险状态变更 POST /networkIncident/riskRecord
 */

export const ApiPostRiskRecord = async (params: {
  status: string
  riskId: number
  remark?: string
}) => {
  return await request
    .post('networkIncident/riskRecord', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 推进状态时 查询评估/风险/子任务状态 PATCH /networkIncident/status/{id}
 */
export const ApiPatchCNSQueryStatus = async (params: {
  id: number
  status: string
}) => {
  return await request
    .patch(`networkIncident/status/${params.id}`, {
      searchParams: { status: params.status }
    })
    .json<API.AdvanceResponens>()
}

/**
 * 网络安全事件 状态推进 PATCH /networkIncident/{id}/status
 */
export const ApiPatchEventStatus = async (params: {
  id: number
  status: string
}) => {
  return await request
    .patch(`networkIncident/${params.id}/status`, {
      searchParams: { status: params.status }
    })
    .json<API.ResponseKy>()
}

/**
 * 网络安全事件 新增事件日志 POST /networkIncident/insertLog/{id}
 */
export const ApiPostEventLog = async (params: {
  id: number
  description?: string
}) => {
  return await request
    .post(`networkIncident/insertLog/${params.id}`, {
      json: {
        description: params.description
      }
    })
    .json<API.ResponseKy>()
}

/**
 * 网络安全事件模块 邮件发送 POST /networkIncident/email
 */
export const ApiPostCNSEmail = async (
  params: API.NetworkSecurityEmailParams
) => {
  return await request
    .post('networkIncident/email', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 同意模块 新增收集目的 POST /purpose/insert
 */
export const ApiPostAddPurpose = async (params: API.PurposeParams) => {
  return await request
    .post('purpose/insert', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 同意模块 编辑收集目的 PATCH /purpose/update/{id}
 */
export const ApiPostEditPurpose = async (params: {
  id: number
  data: API.PurposeParams
}) => {
  return await request
    .patch(`purpose/update/${params.id}`, {
      json: params.data
    })
    .json<API.ResponseKy>()
}

/**
 * 同意模块 查询收集目的是否关联收集点 GET /purpose/select/{id}
 */
export const ApiGetQueryPurposeCorrelation = async (id: number) => {
  return await request(`purpose/select/${id}`).json<
    API.ResponseKy & { count: number }
  >()
}

/**
 * 同意模块 新增收集点 POST /collectorPoint/insert
 */
export const ApiPostCollectorPoint = async (
  params: API.CollectorPointParams
) => {
  return await request
    .post('collectorPoint/insert', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 同意模块 编辑收集点 PATCH /collectorPoint/update/{id}
 */
export const ApiPatchCollectorPoint = async (parmas: {
  id: string
  data: API.CollectorPointParams
}) => {
  return await request
    .patch(`collectorPoint/update/${parmas.id}`, {
      json: parmas.data
    })
    .json<API.ResponseKy>()
}

/**
 * 同意模块 删除收集的 DELETE /collectorPoint/delete/{id}
 */
export const ApiDelCollectorPoint = async (id: string) => {
  return await request
    .delete(`collectorPoint/delete/${id}`)
    .json<API.ResponseKy>()
}

/**
 * 同意管理 收据详情 GET /receipt/select/{id}
 */
export const ApiGetReceiptDetails = async (id: string) => {
  return await request(`receipt/select/${id}`).json<API.DataDetails>()
}

/**
 * 同意管理 数据主体 - 详情 GET /items/data_subject/{id}
 */
export const ApiGetDataSubjectDetails = async (id: string) => {
  return await request(`items/data_subject/${id}`).json<API.SubJectDetails>()
}

/**
 * 同意管理 数据主体 - 编辑数据元素 PATCH /items/data_subject/{data_subject_id}/dataElement
 */
export const ApiPatchDataSubject = async (
  params: API.DataSubjectPatchParms
) => {
  return await request
    .patch(`items/data_subject/${params.id}/dataElement`, {
      json: params.data
    })
    .json()
}

/*
 * 同意管理 数据看板 GET /agreeManageData/select
 */
export const ApiGetAgreeData = async () => {
  return await request.get('agreeManageData/select').json<API.AgreeDataDesk>()
}

/*
 * 同意管理 交易管理 交易详情 GET transaction/select/{id}  请求参数：(path:id(收据ID))
 */
export const APIGetTransactionDetails = async (id: string) => {
  return await request(
    `transaction/select/${id}`
  ).json<API.TransactionDetails>()
}

/**
 * 开发管理 查询是否开通SDK GET /sdk/check
 */
export const ApiGetCheckSDK = async () => {
  return await request('sdk/check').json<{
    enable?: boolean
  }>()
}

/**
 * 开发管理 获取SDK账号 GET /sdk/get
 */
export const ApiGetSDKKeys = async () => {
  return await request('sdk/get').json<{
    errors: []
    access_key?: string
    secret_key?: string
  }>()
}

/**
 * 开发管理 启用/重置 SDK账号 GET /sdk/enable
 */
export const ApiGetEnabledSdk = async () => {
  return await request('sdk/enable').json<{
    access_key?: string
    secret_key?: string
  }>()
}

/**
 * 开发管理 使用AK和SK获取SDK的Token POST /sdk/auth
 */
export const ApiPostSDKAuth = async (params: {
  access_key: string
  secret_key: string
}) => {
  return await request
    .post('sdk/auth', {
      json: params
    })
    .json<{
      token: string
    }>()
}

/**
 * 同意管理 - 收集点管理 -批量导入 POST /collectorPoint/import
 */
export const ApiPostImport = async (params: FormData) => {
  return await request
    .post('collectorPoint/import', {
      body: params
    })
    .json<API.ResponseKy>()
}

/**
 * 同意管理 - 数据主体 - 查询未授权收集目的列表 GET /items/data_subject/{dataSubjectId}/unAuthorizePurposeCollect
 */
export const ApiGetUserPurposeCollect = async (id: string) => {
  return await request(
    `items/data_subject/${id}/unAuthorizePurposeCollect`
  ).json<
    {
      id: number
      title: string
      customPreferenceList: API.CustomPreferenceSubject[]
    }[]
  >()
}

/**
 * 同意管理 - 数据主体详情 - 新增收集目的 PATCH /items/data_subject/{dataSubjectId}/purpose_collect/{purposeCollectId}
 */
export const ApiPatchAddPurposeCollect = async (params: {
  dataSubjectId?: string
  purposeCollectId?: number
  data: {
    custom_preference_id: number | string
    data_content: string[]
  }[]
}) => {
  return await request
    .patch(
      `items/data_subject/${params.dataSubjectId}/purpose_collect/${params.purposeCollectId}`,
      {
        json: params.data
      }
    )
    .json()
}

/**
 * 同意管理 - 数据主体详情 - 撤回收集目的 /items/data_subject/data_subject_purpose_collect_list/{id}
 */

export const ApiDelDataSubjectPurposeCollect = async (params: {
  purposeCollectId: number
  collectorPointId?: string
  dataSubjectId?: string
}) => {
  return await request
    .delete(`items/data_subject/data_subject_purpose_collect_list`, {
      json: params
    })
    .json()
}

/**
 * 评估模板 - 评估详情 - DPO指定用户填写
 */
export const ApiPostUpdateSnapshot = async (params: {
  assessment_id: number
  section_id: number
  user_id: string
}) => {
  return await request
    .post('assessment/updateSnapshot', {
      json: params
    })
    .json<API.ResponseKy>()
}

/**
 * 评估模板 - 评估详情 - 导出评估 GET /assessment/export/{id}
 */
export const ApiGetExprotAssessment = async (id: number) => {
  return await request(`assessment/export/${id}`).blob()
}

/**
 * 网络安全事件管理 - 事件详情 - 导出报告 - 基本描述模板 GET /networkIncident/export/{id}
 */
export const ApiGetExprotReport = async (id: number) => {
  return await request(`networkIncident/export/${id}`).blob()
}

/**
 * 网络安全事件管理 - 事件详情 - 导出报告 - 判断模板 GET /networkIncident/export/decide/{id}
 */
export const ApiGetExprotReportOther = async (id: number) => {
  return await request(`networkIncident/export/decide/${id}`).blob()
}

/**
 * 网络安全事件管理 - 事件详情 - 事件日志 GET networkIncident/loge/page/{id}
 */
export const ApiGetEventLogs = async (
  params: API.EventLogsParams,
  id: number
) => {
  return await request
    .get(`networkIncident/loge/page/${id}`, {
      searchParams: { ...params }
    })
    .json<API.EventLogsResponse>()
}

/**
 * 同意管理 - 收集目的管理 - 删除目的 DELETE purpose/{id}
 */
export const ApiDeleteCollectPurpose = async (id: number) => {
  return await request
    .delete(`purpose/${id}`)
    .json<API.DeleteCollectPurposeResponse>()
}
