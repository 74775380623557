import { Directus, LocalStorage } from '@directus/sdk'
import { message } from 'antd'
import { host } from './config'
import { GenCollention } from './directus_type'

const directus = new Directus<GenCollention>(host + '/', {
  storage: new LocalStorage()
})

interface ResponseObject {
  data: {
    errors?: {
      message: string
    }[]
  }
}

interface ResponseErr {
  message: string
  response?: {
    data: {
      code: number
    }
    status: number
  }
}

// @ts-expect-error axios 未暴露
directus.transport.axios.interceptors.response.use(
  (res: ResponseObject) => {
    res.data.errors?.forEach((error) => {
      message.error(error.message)
    })

    return res
  },
  (err: ResponseErr) => {
    if (
      err.response === undefined ||
      err.response?.status === 403 ||
      err.response?.data.code === 403
    ) {
      // localStorage.clear()
      // window.location.href = '/login'
    } else {
      message.error(err.message)
    }
    return Promise.reject(err)
  }
)

export default directus
