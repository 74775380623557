import { Component, ReactNode } from 'react'
class PointerError extends Component {
  componentDidCatch(error: any) {
    console.log('Error::::', error)
    if (String(error).includes('Loading chunk')) {
      window.location.reload()
    }
  }

  render(): ReactNode {
    return this.props.children
  }
}

export default PointerError
