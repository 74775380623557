import React, { PropsWithChildren } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import LazyWrapper from '@/layout/components/LazyWrapper'
import directus from '@/server/directus'
import { useRecoilValueLoadable } from 'recoil'
import { companySelector, userDetail } from '@/atoms/user'

const Login = React.lazy(() => import('@/views/Login'))
const Register = React.lazy(() => import('@/views/Register'))
const FirmCreate = React.lazy(() => import('@/views/FirmCreate'))
const Invitation = React.lazy(() => import('@/views/Invitation'))
const Team = React.lazy(() => import('@/views/Team'))
const Evaluate = React.lazy(() => import('@/views/Evaluate'))
const NetworkSecurity = React.lazy(() => import('@/views/NetworkSecurity'))
const Layout = React.lazy(() => import('@/layout'))
const Agree = React.lazy(() => import('@/views/Agree'))
const DemoApp = React.lazy(() => import('@/views/Demo'))
const SchemaTest = React.lazy(() => import('@/components/SchemaForm/PageTest'))
const PrivacyAgreement = React.lazy(() => import('@/views/PrivacyAgreement'))

function AuthRoute(props: PropsWithChildren<{}>) {
  const userDetailLoadable = useRecoilValueLoadable(userDetail)
  if (userDetailLoadable.state === 'loading') {
    return null
  }
  if (userDetailLoadable.state === 'hasValue' && directus.auth.token) {
    return <>{props.children}</> ?? null
  }
  return <Navigate to="/login" />
}

function CompanyGrand(
  props: PropsWithChildren<{
    fallback: React.ReactNode
  }>
) {
  const companyLoadable = useRecoilValueLoadable(companySelector)
  if (companyLoadable.state === 'loading') {
    return null
  }

  return <>{companyLoadable.contents ? props.children : props.fallback}</>
}

const Router = () => {
  return (
    <Routes>
      <Route
        path="/privacyAgreement"
        element={
          <LazyWrapper>
            <PrivacyAgreement />
          </LazyWrapper>
        }
      ></Route>
      <Route
        path="/demo"
        element={
          <LazyWrapper>
            <DemoApp />
          </LazyWrapper>
        }
      ></Route>
      <Route
        path="/schema"
        element={
          <LazyWrapper>
            <SchemaTest />
          </LazyWrapper>
        }
      ></Route>
      <Route
        path="/login"
        element={
          <LazyWrapper>
            <Login />
          </LazyWrapper>
        }
      ></Route>
      <Route
        path="/register"
        element={
          <LazyWrapper>
            <Register />
          </LazyWrapper>
        }
      ></Route>
      <Route
        path="/invitation"
        element={
          <AuthRoute>
            <CompanyGrand
              fallback={
                <LazyWrapper>
                  <Invitation />
                </LazyWrapper>
              }
            >
              <Navigate replace to="/" />
            </CompanyGrand>
          </AuthRoute>
        }
      ></Route>
      <Route
        path="/createFirm"
        element={
          <AuthRoute>
            <CompanyGrand
              fallback={
                <LazyWrapper>
                  <FirmCreate />
                </LazyWrapper>
              }
            >
              <Navigate replace to="/" />
            </CompanyGrand>
          </AuthRoute>
        }
      ></Route>
      <Route
        path="/"
        element={
          <AuthRoute>
            <CompanyGrand fallback={<Navigate replace to="/invitation" />}>
              <LazyWrapper>
                <Layout />
              </LazyWrapper>
            </CompanyGrand>
          </AuthRoute>
        }
      >
        <Route index element={<Navigate to="/team/member" />}></Route>
        <Route
          path="/team/*"
          element={
            <LazyWrapper>
              <Team />
            </LazyWrapper>
          }
        ></Route>
        <Route
          path="/evaluate/*"
          element={
            <LazyWrapper>
              <Evaluate />
            </LazyWrapper>
          }
        ></Route>
        <Route
          path="/networkSecurity/*"
          element={
            <LazyWrapper>
              <NetworkSecurity />
            </LazyWrapper>
          }
        ></Route>
        <Route
          path="/agree/*"
          element={
            <LazyWrapper>
              <Agree />
            </LazyWrapper>
          }
        ></Route>
        <Route path="/403" element={<div>暂无权限</div>}></Route>
      </Route>
    </Routes>
  )
}

export default Router
