import directus from '@/server/directus'
import { UserItem } from '@directus/sdk'
import { selectorFamily, selector } from 'recoil'
import { ApiGetRoles } from '@/server/apis'
// import { useUserMe } from '@/server/querys/user'

export interface UserData extends UserItem {
  menu_permissions?: {
    key: string
  }[]
  roles?: {
    directus_roles_id?: {
      name: string
      id: string
    }
    directus_users_id?: {
      id: string
    }
    id: number
  }[]
  companyName?: string
}

export interface DposRoles {
  // 管理员ID
  ADMIN_ID?: string
  // 数据专员ID
  COMMISSIONER_ID?: string
  // 普通成员
  ORDINARY_ID?: string
  // DPO ID
  ROLES_DPO_ID?: string
  // 企业创建人 ID
  ROLES_FOUNDER_ID?: string
}

async function getUsers() {
  try {
    const localToken = localStorage.getItem('auth_token')
    if (!directus.auth.token) {
      if (localToken) {
        directus.storage.auth_token = localToken
      } else {
        return
      }
    }
    const res = await directus.users.me.read({
      fields:
        'email,first_name,id,menu_permissions,user_status,roles.*.id,roles.*.name,role,company_id'
    })

    return {
      ...res,
      menu_permissions: res.menu_permissions ?? [],
      email: res.email ?? ''
    }
  } catch (e) {
    return undefined
  }
}

async function getRoles() {
  const localToken = localStorage.getItem('auth_token')
  if (!directus.auth.token) {
    if (localToken) {
      directus.storage.auth_token = localToken
    } else {
      return
    }
  }

  try {
    const res = await ApiGetRoles()
    let roles: DposRoles = {}
    if (res.length > 0) {
      res.forEach((item) => {
        switch (item.name) {
          case '普通成员':
            roles['ORDINARY_ID'] = item.id
            break
          case '数据专员':
            roles['COMMISSIONER_ID'] = item.id
            break
          case '管理员':
            roles['ADMIN_ID'] = item.id
            break
          case 'DPO':
            roles['ROLES_DPO_ID'] = item.id
            break
          case '企业创建人':
            roles['ROLES_FOUNDER_ID'] = item.id
            break
          default:
            break
        }
      })

      return roles
    }
  } catch (error) {}
}

export const userDetail = selector<UserData | undefined>({
  key: 'userDetail',
  async get() {
    const detail = await getUsers()
    return detail
  }
})

export const dposRoles = selector<DposRoles | undefined>({
  key: 'dposRoles',
  async get() {
    const roles = await getRoles()
    return roles
  }
})

export const companySelector = selector<number | undefined>({
  key: 'companyId',
  get({ get }) {
    return get(userDetail)?.company_id
  }
})

export const useUserID = selector<string | undefined>({
  key: 'useUserID',
  get({ get }) {
    const details = get(userDetail)

    return details?.id
  }
})

export const usesIsDPO = selector<boolean>({
  key: 'isDPO',
  get({ get }) {
    const details = get(userDetail)

    const roles = details?.roles

    if (roles && details?.id) {
      const role = roles.find((item) => item?.directus_roles_id?.name === 'DPO')

      return role?.directus_users_id?.id === details.id
    }

    return false
  }
})

export const useEmail = selector({
  key: 'useEmail',
  get({ get }) {
    const details = get(userDetail)

    return details?.email
  }
})

export const userPermissions = selector({
  key: 'userPermissions',
  get({ get }) {
    const details = get(userDetail)

    return details?.menu_permissions
  }
})

export const havePermission = selectorFamily({
  key: 'havePermission',
  get:
    (permission: string) =>
    ({ get }) => {
      return (
        get(userDetail)?.menu_permissions?.some(
          (item) => item.key === permission
        ) ?? false
      )
    }
})
